import styled from "styled-components"
import { H3 } from "../page-elements/"
import Img from "gatsby-image"

import { device } from "../../utils/responsive"

export const StyledTextContent = styled.div`
  height: auto;
  //min-height: 20vh;
  //background-color: lightcoral;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 60%;
  //line-height: 3vh;

  @media ${device.mobileS} {
  }
  @media ${device.mobileM} {
  }
  @media ${device.mobileL} {
    min-height: 18vh;
    overflow: unset;
  }
  @media ${device.mobileXL} {
  }
  @media ${device.tablet} {
    width: 100%;
  }
  @media ${device.laptop} {
  }
  @media ${device.laptopL} {
  }

  //overflow: hidden;
  /* text-overflow: ellipsis;
  white-space: normal; */
`

export const StyledImg = styled(Img)`
  /* width: 100%;
  height: 20vh; */
  width: 100%;
  height: 100%;
  max-height: 30vh;
  clip-path: polygon(0% 0%, 100% 0%, 100% 80%, 0% 100%);
  @media ${device.mobileS} {
  }
  @media ${device.mobileM} {
  }
  @media ${device.mobileL} {
  }
  @media ${device.mobileXL} {
  }
  @media ${device.tablet} {
    flex-direction: row;
    max-height: unset;
    min-height: 50vh;
    max-height: unset;
    height: 100%;
    //min-height: 100vh;
    /* min-height: 70vh;
    max-height: unset; */
    //width: 100vw;
    clip-path: polygon(0% 0%, 80% 0%, 100% 100%, 0% 100%);
  }
  @media ${device.laptop} {
  }
  @media ${device.laptopL} {
    min-height: 33vh;
    //max-height: 33vh;
  }
`

export const ClassContainer = styled.div`
  //position: relative;
  /* width: 60vw;
  min-height: 40vh; */
  width: 60vw;
  //height: 63vh;
  display: flex;
  flex-direction: column;
  align-items: center;
 /*  margin: ${({ theme }) => theme.spacing["16"]}; */
  background-color: #fffffe;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.02),
    0 6.7px 5.3px rgba(0, 0, 0, 0.028), 0 12.5px 10px rgba(0, 0, 0, 0.035),
    0 22.3px 17.9px rgba(0, 0, 0, 0.042), 0 41.8px 33.4px rgba(0, 0, 0, 0.05),
    0 100px 80px rgba(0, 0, 0, 0.07);

  @media ${device.mobileS} {
  }
  @media ${device.mobileM} {
  }
  @media ${device.mobileL} {
  }
  @media ${device.mobileXL} {
  }
  @media ${device.tablet} {
    flex-direction: row;
    width: 60vw;
    height: 70vh;
  }
  @media ${device.laptop} {
  }
  @media ${device.laptopL} {
  }
`

export const ClassDescription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.spacing["8"]};
  text-align: center;
  width: 100vw;
`

/* export const ClassImg = styled(Image)`
    & div > picture > img {
      width: 50%;
      height: 100%;
      clip-path: polygon(20% 0%, 100% 0%, 100% 100%, 0% 100%);
    }
  ` */

export const ClassTitle = styled(H3)`
  font-weight: ${({ theme }) => theme.fontWeight.semibold};

  text-transform: uppercase;

  width: 60%;

  @media ${device.mobileS} {
  }
  @media ${device.mobileM} {
  }
  @media ${device.mobileL} {
  }
  @media ${device.mobileXL} {
  }
  @media ${device.tablet} {
    width: 100%;
  }
  @media ${device.laptop} {
  }
  @media ${device.laptopL} {
  }
`
