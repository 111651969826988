import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql, navigate } from "gatsby"
import { connect } from "react-redux"
import {
  FetchFitogramDataStart,
  resetFilter,
  setShowSchedule,
  setFilter,
  setScheduleLocation,
} from "../../redux/actions"
import { selectShowSchedule } from "../../redux/selectors"
import SEO from "../../components/seo"
import AutoplaySlider from "../../components/auto-play-slider/auto-play-slider.component"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import Class from "../../components/class/class.component"
import Schedule from "../../components/schedule/schedule.component"
import CustomButton from "../../components/custom-button/custom-button.component"
import useCurrentWidth from "../../utils/useCurrentWidth"
import {
  Container,
  ButtonsContainer,
  Title,
  ClassesContainer,
  CoverImg,
  CoverImgContainer,
  InstructionsContainer,
  TextOverImage,
  SecondTitle,
  SliderContainer,
} from "./classes.styles"
import { scroller } from "react-scroll"
const Classes = ({
  fetchClassesStart,
  resetFilter,
  showSchedule,
  setShowSchedule,
  setScheduleLocation,
  setFilter,
  loading,
}) => {
  const { instructions, classes } = useStaticQuery(graphql`
    query ClassesQuery {
      instructions: mdx(
        fileAbsolutePath: { regex: "/instructions/instructions.mdx/" }
      ) {
        body
        frontmatter {
          id
          title
          subtitle
          featuredimage {
            src {
              childImageSharp {
                fluid(maxWidth: 1024) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            alt
          }
        }
      }
      classes: allMdx(filter: { fileAbsolutePath: { regex: "/classes//" } }) {
        nodes {
          id
          body
          frontmatter {
            title

            featuredimage {
              src {
                childImageSharp {
                  fluid(maxWidth: 1024) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              alt
            }
          }
        }
      }
    }
  `)
  const [days2Show, setDays2Show] = useState(3)
  const [showSubCategories, setShowSubCategories] = useState(true)
  let width = useCurrentWidth()
  useEffect(() => {
    fetchClassesStart()
    return () => {
      resetFilter()
      setShowSchedule(false)
    }
  }, [fetchClassesStart, resetFilter, setShowSchedule])
  useEffect(() => {
    if (!loading && window.history.state?.showSchedule) {
      setFilter({ type: "location", value: "Online Classes" })
      setShowSchedule(true)
    }
  }, [loading])
  useEffect(() => {
    if (width <= 640) {
      setDays2Show(1)
    }

    if (width >= 768 && width <= 1280) {
      setDays2Show(2)
    }
    if (width > 1280) {
      setDays2Show(3)
    }
  }, [width])

  return (
    <>
      <SEO title="Green Yoga Classes | Live Online Yoga Classes" />
      <Container>
        {instructions && (
          <InstructionsContainer>
            <CoverImgContainer>
              <CoverImg
                fluid={
                  instructions.frontmatter.featuredimage.src.childImageSharp
                    .fluid
                }
                alt={instructions.frontmatter.featuredimage.alt}
              />
              <TextOverImage>
                <Title>{instructions.frontmatter.title}</Title>
              </TextOverImage>
            </CoverImgContainer>
          </InstructionsContainer>
        )}
        <ButtonsContainer>
          <CustomButton
            inverted={true}
            onClick={() => {
              !showSchedule &&
                scroller.scrollTo("schedule", {
                  duration: 10,
                  delay: 0,
                  smooth: true,
                  offset: 100,
                })
              setFilter({ type: "location", value: "Online Classes" })
              setShowSchedule(true)
              setScheduleLocation("Online")
            }}
          >
            Online Yoga
          </CustomButton>
          <CustomButton
            inverted={true}
            onClick={() => {
              setShowSchedule(false)
              !showSchedule &&
                scroller.scrollTo("schedule", {
                  duration: 10,
                  delay: 0,
                  smooth: true,
                  offset: 100,
                })
              setShowSubCategories(true)
              setScheduleLocation("Outdoor")
            }}
          >
            Outdoor Yoga
          </CustomButton>
          <CustomButton
            inverted={true}
            onClick={() => {
              setShowSchedule(false)
              !showSchedule &&
                scroller.scrollTo("schedule", {
                  duration: 10,
                  delay: 0,
                  smooth: true,
                  offset: 100,
                })
              setShowSubCategories(true)
              setScheduleLocation("InStudio")
            }}
          >
            InStudio Yoga
          </CustomButton>

          {/*         <CustomButton
            hasMaxWidth
            background={"red"}
            inverted={true}
            onClick={e => {
              // To stop the page reloading
              e.preventDefault()
              trackCustomEvent({
                // string - required - The object that was interacted with (e.g.video)
                category: "button",
                // string - required - Type of interaction (e.g. 'play')
                action: "click",
                // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                label: "start your 7 days trial",
                // number - optional - Numeric value associated with the event. (e.g. A product ID)
                value: 1,
              })
              window.open("https://app.greenyoga.co/dashboard", "_blank")
            }}
          >
            Online Monthly subscription
          </CustomButton> */}
          {/*   <CustomButton
            inverted={true}
            onClick={() => navigate("/schedule/studios")}
          >
            Green Yoga Studios
          </CustomButton> */}
        </ButtonsContainer>

        <Schedule
          days2Show={days2Show}
          key={days2Show}
          instructions={instructions}
          showSubCategories={showSubCategories}
        ></Schedule>
        <SecondTitle>
          Whatever class you are choosing, let’s stay as PRESENT as possible
          throughout the class by FEELING INSTEAD OF THINKING.
        </SecondTitle>
        <CustomButton
          inverted={true}
          onClick={() => navigate("/schedule/studios")}
        >
          Green Yoga Studios
        </CustomButton>
        <SliderContainer>
          <AutoplaySlider
            play={false}
            cancelOnInteraction={true} // should stop playing on user interaction
            interval={6000}
            bullets={false}
          >
            {classes.nodes.length > 0 &&
              classes.nodes.map(item => (
                <ClassesContainer key={item.id}>
                  <Class key={item.id} data={item}></Class>
                </ClassesContainer>
              ))}
          </AutoplaySlider>
        </SliderContainer>
      </Container>
    </>
  )
}

const mapDispatchToProps = dispatch => ({
  fetchClassesStart: () => dispatch(FetchFitogramDataStart()),
  resetFilter: () => dispatch(resetFilter()),
  setShowSchedule: showSchedule => dispatch(setShowSchedule(showSchedule)),
  setFilter: filter => dispatch(setFilter(filter)),
  setScheduleLocation: scheduleLocation =>
    dispatch(setScheduleLocation(scheduleLocation)),
})
const mapStateToProps = state => ({
  showSchedule: selectShowSchedule(state),
  loading: state.loading,
})
export default connect(mapStateToProps, mapDispatchToProps)(Classes)
