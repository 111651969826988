import styled from "styled-components"
import { device } from "../../utils/responsive"

export const StyledSelectSearchWrapper = styled.div`
  margin: 1rem;
`

export const FiltersContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 1rem;
  justify-content: space-evenly;

  align-items: center;

  @media ${device.mobileS} {
  }
  @media ${device.mobileM} {
  }
  @media ${device.mobileL} {
  }
  @media ${device.mobileXL} {
  }
  @media ${device.tablet} {
  }
  @media ${device.laptop} {
    //flex-direction: row;
  }
  @media ${device.laptopM} {
    flex-direction: row;
  }
  @media ${device.laptopL} {
  }
`
export const ResetFilterButton = styled.button`
  //display: block;
  height: 36px;
  width: 100%;
  padding: 0 16px;
  background: #fff;
  //border: 1px solid transparent;
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  outline: none;
  font-size: 14px;
  text-align: left;
  text-overflow: ellipsis;
  line-height: 36px;
  cursor: pointer;
  text-align: center;
  border: 1px solid #e0e0e0;
  & :hover {
    box-shadow: 0 0.1625rem 0.225rem rgba(0, 0, 0, 0.15);
  }
`
