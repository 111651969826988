import React, { useEffect, useMemo, useRef, useState } from "react"
import {
  setFilter,
  setShowSchedule,
  setScheduleLocation,
} from "../../redux/actions.js"
import moment from "moment"
import "./schedule.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import {
  ViewsDirective,
  ViewDirective,
  Day,
  Inject,
} from "@syncfusion/ej2-react-schedule"
import {
  StyledSchedule,
  FitogramLink,
  Container,
  BtnContainer,
} from "./schedule.styles"
import ScheduleFilter from "../schedule-filter/schedule-filter.component"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import {
  selectFilteredResults,
  selectShowSchedule,
  selectScheduleLocation,
} from "../../redux/selectors"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { Element } from "react-scroll"
import { H1, Instructions } from "../page-elements/"

import { useMediaPredicate } from "react-media-hook"
import LeafIcon from "../../images/leaf.svg"

import { getLocation } from "../../utils/getLocation"
import CustomButton from "../custom-button/custom-button.component.js"
const Schedule = ({
  filteredResults,
  showSchedule,
  instructions,
  days2Show,
  scheduleLocation,
  showSubCategories,
  setFilter,
  setShowSchedule,
}) => {
  const [title, setTitle] = useState("")
  const isLaptopOrBigger = useMediaPredicate("(min-width: 1024px)")

  const content = ({
    Id,
    elementType,
    Location,
    StartTime,
    EndTime,
    Description,
    Teacher,
    Category,
  }) => {
    const FitogramURL = `https://widget.fitogram.pro/green-yoga/?w=/event/${Id}`
    /*     if (!Category || !Description || !Category.color || !Category.category) {
      return null
    } */

    return (
      <div>
        <div className="e-event-content e-template flex flex-col ">
          <div className="e-subject-wrap mb-4">
            {Description && (
              <div
                className="flex flex-row items-start"
                style={{
                  display: "flex",
                  paddingTop: "16px",
                }}
              >
                <div className="description">{Description}</div>
              </div>
            )}
            {Teacher && (
              <div
                className="display-flex-x pt-6 font-semibold"
                style={{
                  display: "flex",
                  paddingTop: "16px",
                  fontWeight: 700,
                }}
              >
                <LeafIcon
                  style={{
                    width: "16px",
                    marginRight: "16px",
                  }}
                ></LeafIcon>
                <div className="location"> Taught by {Teacher}</div>
              </div>
            )}
            {StartTime && EndTime && (
              <div
                className="display-flex-x pt-6 items-center font-semibold"
                style={{
                  display: "flex",
                  paddingTop: "16px",
                  fontWeight: 700,
                }}
              >
                <div className="w-4  mr-4 text-gray-400 ">
                  <div
                    className="w-4  mr-1"
                    style={{
                      width: "16px",
                      marginRight: "16px",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </div>
                </div>
                {StartTime.toDateString()}
                {" ("}
                {StartTime.getHours() + ":"}
                {StartTime.getMinutes() < 10 ? "0" : ""}
                {StartTime.getMinutes()}-{EndTime.getHours() + ":"}
                {EndTime.getMinutes() < 10 ? "0" : ""}
                {EndTime.getMinutes()}
                {")"}
              </div>
            )}
            {Location && (
              <div
                className="display-flex-x  pt-6 font-semibold"
                style={{
                  display: "flex",
                  paddingTop: "16px",
                  fontWeight: 700,
                }}
              >
                <div
                  className="w-4  mr-1"
                  style={{
                    width: "16px",
                    marginRight: "16px",
                  }}
                >
                  <svg fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                  </svg>
                </div>
                <div
                  onClick={() =>
                    getLocation(Location) !== ""
                      ? window.open(getLocation(Location))
                      : null
                  }
                  className="location"
                  style={{ cursor: "pointer" }}
                >
                  {Location}
                </div>
              </div>
            )}
            {Id !== undefined ? (
              <div
                className="e-description"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <FitogramLink href={FitogramURL} target="_blank">
                  Book Your Class On Fitogram
                </FitogramLink>
                <span>
                  Please book your class for In studio or Outdoor previous via
                  Fitogram
                </span>
              </div>
            ) : (
              ""
            )}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: "16px",
              }}
            >
              {Category && Category.color && scheduleLocation === "Online" && (
                <button
                  onClick={() =>
                    window.open("https://app.greenyoga.co/signup", "_blank")
                  }
                  style={{
                    padding: "16px",
                    backgroundColor: Category.color,
                    border: "none",
                    color: "white",
                    borderRadius: "3px 15px 15px 15px",
                    justifySelf: "center",
                    fontWeight: 700,
                    textTransform: "uppercase",
                    cursor: "pointer",
                  }}
                >
                  Join On the Platform
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }

  let Schedule = useRef(null)

  useEffect(() => {
    if (!showSchedule) return
    if (isLaptopOrBigger) {
      if (Schedule) {
        Schedule.current.currentView = "Week"
      }
    } else {
      if (Schedule) {
        Schedule.current.currentView = "Agenda"
      }
    }
  }, [Schedule, isLaptopOrBigger])
  const header = props => {
    if (!props.Subject) {
      return null
    }
    return (
      <div
        className="flex justify-center text-xl p-4 text-center border-b-2  relative "
        style={{
          backgroundColor: props.Category?.color
            ? props.Category.color
            : "#2C402B",
          color:
            props.Category?.color === "#2C402B" ||
            props.Category?.color === undefined
              ? "#f9fafb"
              : "#2C402B",
          display: "flex",
          justifyContent: "center",
          fontSize: "20px",
          textAlign: "center",
          borderBottomWidth: "2px",
          position: "relative",
          padding: "16px",
        }}
      >
        <div
          className="w-3/4 font-bold"
          style={{ width: "75%", fontWeight: 700 }}
        >
          {props.Subject.replace(/\b(?:Express)\b/i, "")}
        </div>
        <div
          tabIndex="0"
          role="button"
          className="cursor-pointer absolute right-4 top-0 p-4"
          style={{
            cursor: "pointer",
            position: "absolute",
            top: 0,
            right: "16px",
            padding: "16px",
          }}
          onKeyDown={() => Schedule.current.closeQuickInfoPopup()}
          onClick={() => Schedule.current.closeQuickInfoPopup()}
        >
          <FontAwesomeIcon icon={faTimes} />
        </div>
      </div>
    )
  }
  const maxDate = useMemo(() => {
    let dayOfWeek = 7 //Sunday
    let date = new Date()
    return date.setDate(
      date.getDate() + ((dayOfWeek + 7 - date.getDay()) % 7) + 14
    )
  }, [])

  const minDate = useMemo(
    () => new Date().setDate(new Date().getDate() - 1),
    []
  )

  const onHover = args => {
    if (args.element.classList.contains("e-appointment")) {
      let workCells = document.querySelectorAll(".e-appointment")
      ;[].forEach.call(workCells, function (ele) {
        ele.classList.remove("hover")
      })
      args.element.classList.add("hover")
    }
  }

  const cellTemplate = args => {
    let { data } = args
    //console.log(data)

    if (Schedule.currentView === "Agenda") {
      args.element.firstChild.style.borderLeftColor = data.Category.color
    } else {
      args.element.style.cursor = "pointer"
      args.element.style.borderRadius = "3px 15px 15px 15px"
      args.element.style.color = "#2C402B"
      args.element.style.boxShadow = "0px 0px 18px 0px rgba(91,91,91,0.2)"
      args.element.style.backgroundColor = "#f9fafb"
      if (
        data.StartTime.getTime() < new Date().getTime() &&
        data.EndTime.getTime() < new Date().getTime()
      ) {
        args.element.style.backgroundImage = `repeating-linear-gradient(45deg, #f7f3f0 0, #f7f3f0 1px, #fff 0, #fff 5%)`
      } else if (
        data.StartTime.getTime() < new Date().getTime() &&
        data.EndTime.getTime() > new Date().getTime()
      ) {
        args.element.style.borderColor = "#2C402B"
        args.element.style.borderWidth = "3px"
      } else if (
        data.StartTime.getTime() > new Date().getTime() &&
        data.EndTime.getTime() > new Date().getTime()
      ) {
        args.element.style.backgroundColor = data.Color

        args.element.style.backgroundColor = "#f9fafb"
      }
    }
  }
  const onRenderCell = args => {
    if (args.elementType === "dateHeader") {
      args.element.innerHTML = `	<div>
					<div class='e-header-day'>
						${args.date.toLocaleString("default", {
              weekday: "long",
            })}
					</div>
					<div class='e-header-date e-navigate' role='link'>
						${args.date.getDate()}
					</div>
				</div>`
    }
  }
  const eventTemplate = props => {
    if (!props.Subject) {
      return null
    }

    return (
      <>
        <div
          className="flex flex-col text-brand-dark"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div
            className="flex flex-row flex-wrap items-center justify-between"
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            {props.Category && props.Category.category && props.Category.color && (
              <div className="my-1" style={{ margin: "0.25rem 0" }}>
                <div
                  style={{
                    backgroundColor: props.Category.color,
                    color: `${
                      props.Category.color === "#2C402B"
                        ? " #f9fafb"
                        : "#2C402B"
                    }`,
                    fontWeight: `${
                      props.Category.color === "#2C402B" ? 500 : 700
                    }`,
                    fontSize: "8px",
                    borderRadius: "9999px",

                    paddingLeft: " 0.5rem",
                    paddingRight: " 0.5rem",
                    paddingBottom: " 0.25rem",
                    paddingTop: " 0.25rem",
                  }}
                >
                  <span className="text-xs">{props.Category.category}</span>
                </div>
              </div>
            )}
            {props.StartTime && (
              <div
                className="flex flex-row items-center"
                style={{
                  display: "flex",
                  flexDirection: "row",

                  alignItems: "center",
                }}
              >
                <div
                  className="w-4  mr-1"
                  style={{
                    width: "1rem",
                    marginRight: "0.25rem",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </div>
                {moment(props.StartTime).format("h:mm a")}
              </div>
            )}
          </div>
          {props.Subject && (
            <div
              className="font-bold truncate py-1"
              style={{
                background: props.PrimaryColor,
                fontSize: "16px",
                paddingLeft: "0.25rem",
                paddingRight: "0.25rem",
                fontWeight: 700,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {props.Subject.replace(/\b(?:Express)\b/i, "")}
            </div>
          )}
          <div
            className="truncate flex flex-row items-center  w-full"
            style={{
              background: props.PrimaryColor,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "100%",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          ></div>
          {props.Teacher && (
            <div
              className="flex flex-row"
              style={{
                background: props.PrimaryColor,
                display: "flex",
                flexDirection: "row",
              }}
            >
              <LeafIcon
                style={{
                  width: "1rem",
                  height: "1rem",
                  marginRight: "0.25rem",
                }}
                className="w-4 h-4 mr-1"
              ></LeafIcon>
              {props.Teacher}
            </div>
          )}
        </div>
      </>
    )
  }

  return filteredResults ? (
    <Container showSchedule={showSchedule}>
      {" "}
      <Element name="schedule"></Element>
      {showSubCategories && scheduleLocation === "InStudio" && (
        <Instructions>
          <MDXRenderer>{instructions.body}</MDXRenderer>
        </Instructions>
      )}
      {showSubCategories && scheduleLocation === "Outdoor" && (
        <BtnContainer>
          <CustomButton
            onClick={() => {
              setFilter({ type: "location", value: "Green Yoga VOLKSPARK FX" })
              setTitle("Green Yoga VOLKSPARK FX")
              setShowSchedule(true)
            }}
          >
            VOLKSPARK FX
          </CustomButton>
          <CustomButton
            onClick={() => {
              setFilter({ type: "location", value: "Green Yoga HASENHEIDE" })
              setTitle("Green Yoga HASENHEIDE")
              setShowSchedule(true)
            }}
          >
            Hasenheide
          </CustomButton>
        </BtnContainer>
      )}
      {showSubCategories && scheduleLocation === "InStudio" && (
        <BtnContainer>
          <CustomButton
            onClick={() => {
              setFilter({ type: "location", value: "Green Yoga Kreuzberg" })
              setTitle("Green Yoga Kreuzberg")
              setShowSchedule(true)
            }}
          >
            Green Yoga Kreuzberg
          </CustomButton>
          <CustomButton
            onClick={() => {
              setFilter({
                type: "location",
                value: "Green Yoga Prenzlauerberg",
              })
              setTitle("Green Yoga Prenzlauerberg")
              setShowSchedule(true)
            }}
          >
            Green Yoga Prenzlauerberg
          </CustomButton>
          <CustomButton
            onClick={() => {
              setFilter({
                type: "location",
                value: "Green Yoga SEZ",
              })
              setTitle("Green Yoga SEZ")
              setShowSchedule(true)
            }}
          >
            Green Yoga SEZ
          </CustomButton>
          <CustomButton
            onClick={() => {
              setFilter({
                type: "location",
                value: "Green Yoga Pankow",
              })
              setTitle("Green Yoga Pankow")
              setShowSchedule(true)
            }}
          >
            Green Yoga Pankow
          </CustomButton>          
        </BtnContainer>
      )}
      {showSubCategories && scheduleLocation === "Outdoor" && (
        <div style={{ textAlign: "center" }}>
          Outdoor classes take place in{" "}
          <a
            style={{
              textDecoration: "none",
              cursor: "pointer",
              fontWeight: 600,
              color: "black",
            }}
            href="https://goo.gl/maps/AuUAYyP62SDRqdsd9"
            target="_blank"
          >
            VOLKSPARK FX{" "}
          </a>{" "}
          and{" "}
          <a
            style={{
              textDecoration: "none",
              cursor: "pointer",
              fontWeight: 600,
              color: "black",
            }}
            href="https://www.google.com/maps/place/52%C2%B029'07.7%22N+13%C2%B025'11.3%22E/@52.4854722,13.4176169,17z/data=!4m5!3m4!1s0x0:0x0!8m2!3d52.485457!4d13.419815?hl=en-US"
            target="_blank"
          >
            Hasenheide
          </a>{" "}
        </div>
      )}
      {/*       <ScheduleFilter /> */}
      {showSchedule && <H1>{title}</H1>}
      {showSchedule && (
        <StyledSchedule
          hover={args => onHover(args)}
          cssClass="schedule-cell-dimension"
          height="70vh"
          eventSettings={{
            dataSource: filteredResults,
            fields: {
              location: { name: "Teacher" },
            },
            template: args => eventTemplate(args),
          }}
          eventRendered={args => cellTemplate(args)}
          renderCell={args => onRenderCell(args)}
          startHour="05:00"
          endHour="23:60"
          readonly={true}
          firstDayOfWeek={1}
          quickInfoTemplates={{
            header: e => header(e),
            content: e => content(e),
          }}
          minDate={minDate}
          maxDate={maxDate}
          ref={Schedule}
          /*   renderCell={args => onRenderCell(args)} */
          rowAutoHeight={true}
          isResponsive={true}
          popupOpen={e => {
            if (e.type === "Editor") e.cancel = true
          }}
        >
          <ViewsDirective>
            <ViewDirective option="Day" interval={days2Show} />
          </ViewsDirective>
          <Inject services={[Day]} />
        </StyledSchedule>
      )}
    </Container>
  ) : (
    <div>Loading</div>
  )
}

const mapStateToProps = createStructuredSelector({
  filteredResults: selectFilteredResults,
  showSchedule: selectShowSchedule,
  scheduleLocation: selectScheduleLocation,
})
const mapDispatchToProps = dispatch => ({
  setFilter: filter => dispatch(setFilter(filter)),
  setShowSchedule: showSchedule => dispatch(setShowSchedule(showSchedule)),
  setScheduleLocation: scheduleLocation =>
    dispatch(setScheduleLocation(scheduleLocation)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Schedule)
