import styled from "styled-components"
import { ScheduleComponent } from "@syncfusion/ej2-react-schedule"

export const StyledSchedule = styled(ScheduleComponent)`
  width: 90vw !important;
`
export const FitogramLink = styled.a`
  color: white;
  background-color: green;
  font-size: 16px;
  padding-left: 10px;
  cursor: pointer;
  display: inline-flex;
  font-weight: 600;
  border-radius: 10px;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing["8"]};
`
export const BtnContainer = styled.div`
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  & > button {
    width: 60%;
  }
`
