import React, { useState, useEffect } from "react"
import SelectSearch from "react-select-search"
import { connect } from "react-redux"
import { setFilter, resetFilter, filterSchedule } from "../../redux/actions"
import { createStructuredSelector } from "reselect"
import {
  selectClassesLocations,
  selectTrainersList,
  selectYogaStyles,
  selectCurrentFilter,
  selectScheduleLocation,
} from "../../redux/selectors"
import {
  FiltersContainer,
  ResetFilterButton,
  StyledSelectSearchWrapper,
} from "./schedule-filter.styles"
import "./schedule-filter.css"

const ScheduleFilter = ({
  locations,
  trainers,
  yogaStyles,
  setFilter,
  resetFilter,
  currentFilter,
  scheduleLocation,
}) => {
  const [disableLocation, setDisableLocation] = useState(false)
  useEffect(() => {
    setFilter({ type: "location", value: locations[0].value })
  }, [scheduleLocation])
  return (
    <FiltersContainer>
      <StyledSelectSearchWrapper>
        <SelectSearch
          disabled={disableLocation}
          value={currentFilter.location}
          options={locations}
          onChange={e => {
            setFilter({ type: "location", value: e })
          }}
        />
      </StyledSelectSearchWrapper>

      <StyledSelectSearchWrapper>
        <SelectSearch
          value={currentFilter.class}
          options={yogaStyles}
          placeholder="Yoga Styles"
          onChange={e => {
            setFilter({ type: "class", value: e })
          }}
        />
      </StyledSelectSearchWrapper>

      <StyledSelectSearchWrapper>
        <SelectSearch
          value={currentFilter.teacher}
          options={trainers}
          search
          placeholder="Teacher"
          printOptions="on-focus"
          onChange={e => {
            setFilter({ type: "teacher", value: e })
            e !== "All Teachers"
              ? setDisableLocation(true)
              : setDisableLocation(false)
          }}
        />
      </StyledSelectSearchWrapper>

      <StyledSelectSearchWrapper>
        <ResetFilterButton
          onClick={() => {
            setDisableLocation(false)

            resetFilter()
          }}
        >
          Reset Filters
        </ResetFilterButton>
      </StyledSelectSearchWrapper>
    </FiltersContainer>
  )
}
const mapStateToProps = createStructuredSelector({
  locations: selectClassesLocations,
  trainers: selectTrainersList,
  yogaStyles: selectYogaStyles,
  currentFilter: selectCurrentFilter,
  scheduleLocation: selectScheduleLocation,
})

const mapDispatchToProps = dispatch => ({
  setFilter: filter => dispatch(setFilter(filter)),
  resetFilter: () => {
    dispatch(resetFilter())
    dispatch(filterSchedule())
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleFilter)
