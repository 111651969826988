import React from "react"
import { connect } from "react-redux"
import { setFilter, setShowSchedule } from "../../redux/actions"
import CustomButton from "../custom-button/custom-button.component"
import {
  ClassContainer,
  ClassDescription,
  StyledImg,
  ClassTitle,
  StyledTextContent,
} from "./class.styles"
import { scroller } from "react-scroll"
import { MDXRenderer } from "gatsby-plugin-mdx"
const Class = ({ data, setFilter, setShowSchedule }) => {
  const {
    frontmatter: { title, featuredimage },
    body,
  } = data

  return (
    data !== undefined && (
      <ClassContainer>
        {featuredimage && featuredimage.src && (
          <StyledImg
            fluid={featuredimage.src.childImageSharp.fluid}
            alt={featuredimage.alt}
          />
        )}
        <ClassDescription>
          <ClassTitle>{title}</ClassTitle>

          <StyledTextContent>
            <MDXRenderer>{body}</MDXRenderer>
          </StyledTextContent>
          <CustomButton
            href="#schedule"
            inverted={true}
            onClick={() => {
              setShowSchedule(true)
              setFilter({ type: "class", value: title })
              setFilter({ type: "location", value: "Online Classes" })
              scroller.scrollTo("schedule", {
                duration: 200,
                delay: 0,
                smooth: true,
                offset: 200,
              })
            }}
          >
            Find In schedule
          </CustomButton>
        </ClassDescription>
      </ClassContainer>
    )
  )
}

const mapDispatchToProps = dispatch => ({
  setFilter: filter => dispatch(setFilter(filter)),
  setShowSchedule: showSchedule => dispatch(setShowSchedule(showSchedule)),
})

export default connect(null, mapDispatchToProps)(Class)
