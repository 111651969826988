export const getLocation = location => {
  if (location === "Green Yoga VOLKSPARK FX")
    return "https://goo.gl/maps/AuUAYyP62SDRqdsd9"
  if (location === "Hasenheide")
    return "https://www.google.com/maps/place/52%C2%B029'07.7%22N+13%C2%B025'11.3%22E/@52.4854722,13.4176169,17z/data=!4m5!3m4!1s0x0:0x0!8m2!3d52.485457!4d13.419815?hl=en-US"
  if (location === "Green Yoga Kreuzberg")
    return "https://www.google.com/maps/place/Oranienstra%C3%9Fe+163,+10969+Berlin,+Germany/@52.5025213,13.4119168,17z/data=!3m1!4b1!4m5!3m4!1s0x47a84e326d7d3ea9:0xdd3662c1fc65a250!8m2!3d52.5025213!4d13.4141055"
  if (location === "Green Yoga Prenzlauerberg")
    return "https://www.google.com/maps/place/Sredzkistra%C3%9Fe+59,+10405+Berlin/@52.5379847,13.4192467,17z/data=!3m1!4b1!4m5!3m4!1s0x47a84e0123a140b5:0x249d1cbdc20bfd80!8m2!3d52.5379847!4d13.4214354?shorturl=1"
  return ""
}
