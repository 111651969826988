import styled from "styled-components"
import { H3, P } from "../../components/page-elements"
import Img from "gatsby-image"
import { device } from "../../utils/responsive"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
export const Title = styled(H3)`
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  text-align: center;
  letter-spacing: ${({ theme }) => theme.letterSpacing["4"]};

  text-align: center;
`
export const Subtitle = styled(P)`
  letter-spacing: ${({ theme }) => theme.letterSpacing.widest};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`
export const TextOverImage = styled.div`
  z-index: 1;
  position: absolute;
  //padding: ${({ theme }) => theme.spacing["32"]};
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 1.25rem;

  @media ${device.mobileS} {
  }
  @media ${device.mobileM} {
  }
  @media ${device.mobileL} {
    padding: 1.25rem;
  }
  @media ${device.mobileXL} {
  }
  @media ${device.tablet} {
    padding: 2rem;
  }
  @media ${device.laptop} {
    width: 80%;
  }
  @media ${device.laptopL} {
  }
`
export const ClassesContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: ${({ theme }) => theme.spacing["8"]};
`

export const InstructionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const CoverImg = styled(Img)`
  opacity: 0.6;
  height: 322px;
  position: absolute;
  width: 100vw;
`
export const CoverImgContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
`
export const SecondTitle = styled(H3)`
  width: 70%;
  font-size: ${({ theme }) => theme.fontSize.lg};
  line-height: ${({ theme }) => theme.lineHeight.loose};
  text-align: center;
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  letter-spacing: ${({ theme }) => theme.letterSpacing["3"]};
`

export const SliderContainer = styled.div`
  //min-height: 63vh;
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
